/*** IMPORTS FROM imports-loader ***/
var define = false;

// JQuery page modifications for figures with images and captions

jQuery(document).ready(function($) {
	//set the figure captions width to match the image
	$('.field-name-body figure.image').each(function(){
		var thisIMG = $(this).find('img');

		var styleString = ' ; width: ' + thisIMG.width() + 'px !important;';

		//find float info
		if ($(this).attr('style') == 'float:left') {
			$(this).addClass('left');
			$(this).attr('style', $(this).attr('style') + styleString);
		}
		if ($(this).attr('style') == 'float:right') {
			$(this).addClass('right');
			$(this).attr('style', $(this).attr('style') + styleString);
		}
	});
});

