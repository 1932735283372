/*** IMPORTS FROM imports-loader ***/
var define = false;


var tileID;
import { readInChartCSV } from './jquery.highcharts.plotter';
import {loadGallery} from './jquery.loadGallery';
/**
 * Hijack tile a hrefs
 */
jQuery(document).ready(function($){

  $('.view-document-grid').find('.tile').each(function(){
    $(this).find('a').click(function(evt){
      evt.preventDefault();
      var _regURL = new RegExp('^(?:[a-z]+:)?//', 'i');
      var isAbsolute = false;
      var _linkBlankLoad = false;
      var url = $(this).attr('href'),
        template,
        tileID,
        containerID;

      isAbsolute = _regURL.test(url);
      if (isAbsolute) {
        if (url.indexOf(location.host) == '-1') {
          _linkBlankLoad = true;
        }
      }
      if (url.indexOf('files') > 0) { _linkBlankLoad = true; }

      if (_linkBlankLoad) {
        window.open(url);
      } else if ($(this).hasClass('in-use')) {
        return false;

      } else {
        // disable page scroll
        jQuery('html').css('overflow', 'hidden');

        // clone the template
        var template 	= $('#template').clone();

        // create unique ID
        var uid			= Math.floor(Math.random()*100)+1;

        // generate a tileID
        var tileID		= 'tile-' + uid;

        // generate a buttonID
        var buttonID	= 'button-' + uid;

        // update button attributes
        $(this).attr('id', buttonID);
        $(this).attr('class', 'in-use');

        // update the cloned template with the new tileID
        template.attr('id', tileID);

        // add the cloned template the DOM structure
        $('#main-content').prepend(template);

        //timer delay for a tad
        setTimeout(function() {
          $('#' + tileID).addClass('open');
        },500);

        $('#' + tileID + ' .tile-target').load(url + ' .tile-content', function(response, status, xhr) {
          if (status == 'success') {
            var containerID = $('#' + $('#' + tileID).find('.tile-content').attr('id'));

            $('html, body').animate({ scrollTop: $('#' + tileID).offset().top + $('#page-header').height }, 1000);
            $('.modal.open').attr('aria-expanded', 'true');
            var opened =  $('div.modal.open').attr('id');
            if(opened) {
              $("#"+opened).modalTabbing();
            }


            switch(jQuery('#' + tileID).find('.tile-content').attr('class').split(' ')[0]) {
              case 'chart-container':
                var chartContainer 		= jQuery('#' + jQuery(this).find('.chart-target').attr('id'));
                readInChartCSV(chartContainer);
              break;

              case 'gallery-stage':
                var galleryContainer	= containerID;
                var mySlider = loadGallery(galleryContainer);
              break;

              case 'mediacore-video-container':
                jQuery('#' + tileID).find('p>iframe').unwrap();
              break;

              case 'warpwire-video-container':
                $('#' + tileID).find('p>iframe').unwrap().wrap('<div class="iframe-video-wrapper"/>');
              break;

              case 'html-embedded-content-container':
                var iframeObject = jQuery('#' + tileID).find('p>iframe');
                iframeObject.unwrap();
                var videoObject = jQuery('#' + tileID).find('iframe[src*="youtube"], iframe[src*="vimeo"]');
                if (videoObject.length > 0) {
                  videoObject.wrap('<div class="iframe-video-wrapper"/>');
                }
              break;
            }

            //$('#' + tileID + ' .tile-wrapper').css('background', 'none');
            $(window).resize();
            $(window).resize();

            $(document).keydown(function(event) {
              if (event.keyCode == 27) {
                var opened =  $('div.modal.open').attr('id');
                if(opened) {
                  var openedID = opened.split('-')[1];
                  $('#button-' + openedID).removeClass('in-use');
                }
                $('div.modal.open').remove();
              }
            });
          }
        });
      }
    });
  });

  // remove the modal window
  $(document).on('click', '.modal-close', function(evt) {
    evt.preventDefault();

    // remove the "in-use" class from the correct button ID
    // using the closed modal ID
    $('#button-' + $(this).closest('.modal').attr('id').split('-')[1]).removeClass('in-use');
    $(this).closest('.modal').attr('aria-expanded', 'false');
    // destory the modal
    $(this).closest('.modal').remove();
    // enable page scroll
	  $('html').css('overflow', 'auto');

	  $(document).unbind('keyup');
  });
});

(function($) {

  $.fn.modalTabbing = function() {

    var tabbing = function(jqSelector) {
      var inputs = $(jqSelector).find('select, input, textarea, button, a[href]').filter(':visible').not(':disabled');

      //Focus to first element in the container.
      inputs.first().focus();

      $(jqSelector).on('keydown', function(e) {
        if (e.which === 9) {

          var inputs = $(jqSelector).find('select, input, textarea, button, a[href]').filter(':visible').not(':disabled');

          /*redirect last tab to first input*/
          if (!e.shiftKey) {
            if (inputs[inputs.length - 1] === e.target) {
              e.preventDefault();
              inputs.first().focus();
            }
          }
          /*redirect first shift+tab to last input*/
          else {
            if (inputs[0] === e.target) {
              e.preventDefault();
              inputs.last().focus();
            }
          }
        }
      });
    };

    return this.each(function() {
      tabbing(this);
    });

  };
})(jQuery);

