/*** IMPORTS FROM imports-loader ***/
var define = false;

$(document).ready(function ($) {
  if (jQuery('.document-tiles .view-content').length > 0) {
    new Waypoint({
      element: jQuery('.document-tiles .view-content'),
      handler: function (direction) {
        if (direction === 'down') {
          $('.scroll-to-top.scroll-to-top--floating').addClass('visible');
        }
        if (direction === 'up') {
          $('.scroll-to-top.scroll-to-top--floating').removeClass('visible');
        }
      },
      offset: function () {
        var _docTilesHeight = $('.document-tiles .view-content').height() - 64 - 20;
        return (0 - _docTilesHeight);
      }
    });
  }
});

