/*** IMPORTS FROM imports-loader ***/
var define = false;

//detect ie8 and show modal to suggest chrome
jQuery(document).ready(function($){
	if ($('html.lt-ie9').length > 0){
		$.get( "/sites/all/themes/custom/ysm_cases/assets/partials/browser-detect.html", function( data ) {
			$('article.main-content').prepend(data);
		});

	}
});

