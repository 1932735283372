/*** IMPORTS FROM imports-loader ***/
var define = false;

document.addEventListener('DOMContentLoaded', () => {
  const HTML = document.documentElement;
  const BODY = document.querySelector('body');

  if (BODY.classList.contains('not-logged-in')) {
    const PAGE_HEADER = document.getElementById('page-header');
    const CASE_NAME = PAGE_HEADER.querySelector('h1');
    const MAIN_CONTENT = document.getElementById('main-content');
    const PAGE_TITLE = MAIN_CONTENT.querySelector('h1');

    if (PAGE_TITLE !== null && CASE_NAME !== null) {
      PAGE_TITLE.innerText = CASE_NAME.innerText;
    }
  }
});

