/*** IMPORTS FROM imports-loader ***/
var define = false;

// This is a required alias to the main stlyes file inside the "src" folder.
// DO NOT REMOVE OR MODIFIY THIS!
require('src:styles/cases-global-network.theme.scss');

// The is a required alias to all the static images inside the "src" folder.
// These images are in stylesheets and/or a <img> tags.
// The second argument decides if sub directories should also be searched.
// DO NOT REMOVE OR MODIFIY THIS!
require.context('src:images', true, /\.(gif|png|jpe?g|svg)$/);

/*SLIDER*/

require('waypoints/lib/jquery.waypoints.js');
require('waypoints/lib/shortcuts/sticky.js');

// This is an example of how to include a vendor file.
// Replace "[filename]" with the *.js filename and webpack
// will consume that file.
// require('src:vendors/[path_to_file]');

//require('./parts/jquery.highcharts.plotter');
require('./parts/jquery.loadGallery');
require("./parts/document-tiles");
require('./parts/page-layout');
require('./parts/headers');
require('./parts/boookshelf-scrolltop');
require("./parts/admin-roam");
require("./parts/case-tiles");
require("./parts/figures");
require("./parts/footnotes");
require("./parts/browser-detect");
require("./parts/admin-roam");

// get from YSM
require("../../../../ysm_cases/assets/src/js/parts/case-modal.js");
require("../../../../ysm_cases/assets/src/js/parts/update-page-title.js");

