/*** IMPORTS FROM imports-loader ***/
var define = false;

document.addEventListener('DOMContentLoaded', () => {
  const HTML = document.documentElement;
  const PAGE_FOOTER = document.getElementById('page-footer');
  const CASE_MODAL = document.querySelector('[data-js="case-modal"]');
  const DATE = new Date();
  const EPOCH_TIME = DATE.getTime();

  if (CASE_MODAL !== null) {
    const CASE_NID = CASE_MODAL.getAttribute('data-case-nid');
    const RAW_CASE_LS = localStorage.getItem(`raw-case-${ CASE_NID }`);

    let showModal = true;

    if (EPOCH_TIME < RAW_CASE_LS) {
      showModal = false;
    }

    if (showModal) {
      CASE_MODAL.classList.add('case-modal--visible');
      // Get the modal button so the user can close the modal.
      const CASE_MODAL_CLOSE_BTN = CASE_MODAL.querySelector('[data-js="modal-close-btn"]');

      CASE_MODAL_CLOSE_BTN.addEventListener('click', () => {
        closeModal();
      });

      // Move the modal just above the footer element.
      PAGE_FOOTER.parentNode.insertBefore(CASE_MODAL, PAGE_FOOTER);

      // Prevent the document from scrolling while the modal is opened.
      HTML.style.overflowY = "hidden";
    }

    function closeModal() {
      // Set cookie for 8 hours, for this case.
      localStorage.setItem(`raw-case-${ CASE_NID }`, EPOCH_TIME + 8 * 60 * 60 * 1000);

      // Enable document scroll.
      HTML.style.overflowY = null;

      // Delete the modal.
      CASE_MODAL.parentNode.removeChild(CASE_MODAL);
    }
  }
});

