/*** IMPORTS FROM imports-loader ***/
var define = false;

var headerHeight, navHeight, pageHeader;


(function($) {
/*
*  Header scripts
*/

  $(document).ready(function() {
      var ulTags = $( "ul.section > li" );
      if ( ulTags.parent().is( "div.item-list" ) ) {
          ulTags.unwrap();
      }

      $(document).keydown(function(event) {
          if (event.keyCode == 27) {
              if($('div.modal').hasClass('open')) {
              } else {
                  resizeHeader();
              }
          }
      });
  });

  if ($('body').hasClass('node-type-book') || $('body').hasClass('node-type-page')) {
    // !Toggle animation for top header
    function resizeHeader(){
      // IsMobile.detect() left menu toggle
      if (document.body.clientWidth <= 568 && IsMobile.detect()) {
        jQuery('.primary-nav > .block-views').height(window.innerHeight);
        jQuery('#page-wrapper').toggleClass('open');
      }
      // Desktop dropdown toggle
      else {
        // ! Test if header is open or closed
        pageHeader = jQuery('#page-header');
        if ( pageHeader.hasClass('closed') ){
          $('#page-header .primary-nav').getNiceScroll().show();
          oneAtATime = true;
          pageHeader.removeClass('closed').find('.primary-nav').height(0).show();
                $('#page-header').attr('aria-disabled','false');
          headerHeight = pageHeader.find('.section-wrap').outerHeight(true) + pageHeader.find('.primary-nav > .block').outerHeight(true);
          navHeight = pageHeader.find('.primary-nav > .block').outerHeight(true);
        } else {
          $('#page-header .primary-nav').getNiceScroll().hide();
          oneAtATime = false;
          headerHeight = pageHeader.find('.section-wrap').outerHeight(true) - pageHeader.find('.primary-nav > .block').outerHeight(true);
          navHeight = 0;
          pageHeader.addClass('closed');
          $('#page-header').attr('aria-disabled','true');
        }
        // Test header height to make sure it does not exceed screen height
        if ( headerHeight > jQuery(window).height() ) {
          headerHeight = jQuery(window).height();
          navHeight = jQuery(window).height() - pageHeader.find('.header-main').outerHeight(true);
        }
        pageHeader.parent().animate({
          height: headerHeight
        }, 500, 'easeInOutQuad');
        pageHeader.find('.primary-nav').animate({
          height: navHeight
        }, 500, 'easeInOutQuad',
        function(){
          // callback
          // jQuery('.touch #page-header .primary-nav').css('overflow','scroll');
          //getWaypointHeight();
        });

      }
    };

    function getWaypointHeight() {
      // subtract nav height from header container
      return jQuery('#page-header .section-wrap').outerHeight(true) - 2 + jQuery('#toolbar').height();
    }

    // ! Stop navigation click/touch events from bubbling up
    jQuery('.primary-nav .view-book-outline a').bind('click touch', function(e){
      e.stopPropagation();
    });

    // !Sticky Header
    new Waypoint.Sticky({
      element: jQuery('#page-header')[0],
      offset: function(){
        return jQuery('#toolbar').height();
      }
    });

    var oneAtATime = true;
    var mainContentWaypoint = new Waypoint({
      element: jQuery('.node-type-book #main-content, .node-type-page #main-content'),
      handler: function(direction){
        if (direction === 'down' && oneAtATime) {
          oneAtATime = false;
          resizeHeader();

          // Disable waypoint to scroll
          mainContentWaypoint.disable();
          var enableWaypoint = window.setTimeout(function(){
            mainContentWaypoint.enable();
          },500);
        }
        if (direction === 'up' && !oneAtATime) {
          oneAtATime = true;
          resizeHeader();
          jQuery(scrollElem).animate({scrollTop: 0}, 500, 'easeInOutQuad', function(){
            jQuery('#page-header').removeClass('overlay');
          });
        }
      },
      offset: function() {
        return getWaypointHeight();
      }
    });

    // ! Move navigation container and disable waypoint outside page-wrapper for IsMobile.detect()
    if (document.body.clientWidth <= 568 && IsMobile.detect()) {
      jQuery('body').addClass('phone');
      jQuery('#page-header .primary-nav').prependTo( jQuery('#page-wrapper') );
      mainContentWaypoint.disable();
    }

    // !Navigation click/touch event
    jQuery('#page-header, .primary-nav').bind('touch click', function(){
      resizeHeader();
    });
    // !Add navigation close button
    jQuery('#page-header .section-wrap').append('<i class="icon-remove"></i>')
    jQuery('#page-header .icon-cancel').bind('touch click', function(){
      resizeHeader();
    });

    // ! Add placehold attributes to login form
    jQuery('.user-login #edit-name').attr('placeholder','Username');
    jQuery('.user-login #edit-pass').attr('placeholder','Password');

    // !Add padding to fixed header for admin users
    var adminSticky = window.setTimeout(function(){
      jQuery('.toolbar #page-header').css('top', jQuery('body.toolbar').css('padding-top') );
    },200);

    var scrollElem = scrollableElement('html', 'body');
    // !Activate Top link in footer
    jQuery('.scrolltoTop').click(function(e){
      e.preventDefault();
      jQuery(scrollElem).animate({scrollTop: 0}, 400);
    })
    // use the first element that is "scrollable"
    function scrollableElement(els) {
      for (var i = 0, argLength = arguments.length; i <argLength; i++) {
        var el = arguments[i],
        $scrollElement = jQuery(el);
        if ($scrollElement.scrollTop()> 0) {
          return el;
        } else {
          $scrollElement.scrollTop(1);
          var isScrollable = $scrollElement.scrollTop()> 0;
          $scrollElement.scrollTop(0);
          if (isScrollable) {
            return el;
          }
        }
      }
      return [];
    }
  }
})(jQuery);

