/*** IMPORTS FROM imports-loader ***/
var define = false;

import Swiper from 'swiper';

export function loadGallery(targetId) {
  var $ = jQuery;
  var galleryStage = $(targetId);
  var galleryContainer = galleryStage.find('.swiper-container');
  var	options 	= galleryStage.data();
  var params = {};

  console.table(options);

  params.effect = options.transitionmode;
  if (options.transitionmode == 'fade') {
    params.loop = false;
  } else {
    params.loop = true;
  }
  if (options.adaptiveheight == true) {
    params.autoHeight = true;
  }
  params.pagination = {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    };
  params.navigation = {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    };
  if (options.autoplay == true) {
    params.autoplay = {
      delay: options.transitiondelay,
    };
  }

  console.table(params);

  var mySlider = new Swiper(galleryContainer, params);

  // move the play pause buttons if they exist inside the pagination container;
  if (options.autoplay == true) {
    galleryStage.find('.swiper-play-pause').appendTo(galleryStage.find('.swiper-pagination'));
    // set clicks for buttons
    galleryStage.find('.swiper-button-pause').click(function(){
      mySlider.autoplay.stop();
      galleryStage.find('.swiper-button-pause').addClass('paused');
    });
    galleryStage.find('.swiper-button-play').click(function(){
      mySlider.autoplay.start();
      galleryStage.find('.swiper-button-pause').removeClass('paused');
    });
  }

  return mySlider;
}

